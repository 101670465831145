<template>
  <div>
    <v-app-bar style="z-index: 1006">
      <template #prepend>
        <v-btn
          rounded="xl"
          @click="clickOnProfile()"
        >
          <template #prepend>
            <profile-photo
              :size="35"
              data-cy="avatar"
              class="onbottom cursor-pointer"
              :user-id="currentUser.id"
              readonly
              @mousedown="clickOnProfile()"
            />
          </template>

          <div
            v-if="$vuetify.display.mdAndUp"
            class="text-body-1 font-weight-regular primary-darken cursor-pointer menu-profile-name"
            data-cy="profileName"
          >
            {{ currentUser.first_name }}
            {{ currentUser.last_name }}
            <!--v-icon>mdi mdi-chevron-down</v-icon-->
          </div>
        </v-btn>
      </template>
      <router-link
        to="/auth/forward"
        class="cursor-pointer center-me"
        @click="clickOnLogoLink()"
      >
        <v-img
          v-if="$vuetify.display.smAndUp"
          src="/img/logo.svg"
          height="30"
          width="150"
          alt="SEDIWORK Logo"
          class="cursor-pointer mx-auto"
        />
        <v-img
          v-else
          src="/img/logo-icon.svg"
          height="40"
          width="150"
          alt="SEDIWORK Logo"
          class="cursor-pointer mx-auto"
        />
      </router-link>

      <app-notification-icon
        ref="notificationIcon"
        class="mt-1"
        @click="notificationbar = !notificationbar"
      />
      <app-navigation-administrative v-if="hasRole(['ADMINISTRATIVE_STAFF'])" />
      <v-app-bar-nav-icon
        v-if="hasRole(['ROTATING_STAFF'])"
        variant="text"
        data-cy="menuButton"
        @click="toggleMenu"
      >
        <v-icon v-if="!showMenu">mdi-menu </v-icon>
        <v-icon v-if="showMenu"> mdi-close </v-icon>
      </v-app-bar-nav-icon>
    </v-app-bar>
    <app-notification-bar
      ref="notificationBar"
      v-model="notificationbar"
    />
  </div>
</template>
<script>
export default {
  props: {
    showMenu: {
      type: Boolean,
    },
  },
  emits: ['toggle-menu'],
  data: () => ({
    menuOpen: false,
    notificationbar: false,
  }),
  async mounted() {
    const query = {
      filter: { status: { _in: ['UNREAD', 'READ', 'ARCHIVED'] } },
    }
    await this.subscribeToRealTime(
      'notifications',
      ['create', 'update'],
      query,
      this.handleNotification
    )
  },
  methods: {
    toggleMenu() {
      this.$emit('toggle-menu')
    },
    clickOnProfile() {
      if (this.showMenu) {
        this.toggleMenu()
      }
      this.$router.push('/profile/overview')
    },
    clickOnLogoLink() {
      if (this.showMenu) {
        this.toggleMenu()
      }
    },
    handleNotification(data) {
      if (data.type == 'subscription' && (data.event == 'create' || data.event == 'update')) {
        this.$refs.notificationIcon.loadData()
        this.$refs.notificationBar.loadData()
      }
    },
  },
}
</script>
<style lang="scss">
.menu-profile-avatar {
  float: left;
}

.menu-profile-name {
  float: left;
  line-height: 150%;
}

.avatarmask {
  display: block;
  width: 40px;
  height: 40px;
  position: absolute;
}

.center-me {
  position: absolute;
  margin-left: 50%;
  transform: translate(-50%, 0);
}
</style>
