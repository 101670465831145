<template>
  <div class="mx-3">
    <v-btn
      class="cursor-pointer"
      size="small"
      icon
      @click="$emit('click')"
    >
      <v-badge
        class="pulse-secondary"
        :content="notificationCount"
        :model-value="!!notificationCount"
        color="green"
      >
        <v-icon
          icon="mdi-bell-outline"
          size="large"
        />
      </v-badge>
    </v-btn>
  </div>
</template>

<script>
export default {
  props: {
    modelValue: {
      type: Boolean,
    },
  },
  emits: ['update:modelValue', 'click'],
  data() {
    return {
      notificationbar: false,
      notificationCount: 0,
    }
  },
  computed: {},
  mounted() {
    this.loadData()
  },
  methods: {
    async loadData() {
      try {
        const response = await this.$cms.request(
          this.$readItems('notifications', {
            filter: {
              status: {
                _eq: 'UNREAD',
              },
            },
            fields: ['id', 'type'],
            limit: -1,
          })
        )
        // Group notifications by type and count the number of distinct types
        const grouped = response.reduce((acc, notif) => {
          if (!acc.includes(notif.type)) {
            acc.push(notif.type)
          }
          return acc
        }, [])
        this.notificationCount = grouped.length
      } catch (error) {
        console.log('Could not get notifications: ' + error)
      }
    },
  },
}
</script>
<style lang="scss">
.pulse-secondary {
  .v-badge__badge {
    box-shadow: 0 0 0 rgba(44, 204, 89, 0.4);
    animation: pulse-secondary 2s infinite;
  }
}

@-webkit-keyframes pulse-secondary {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(103, 204, 44, 0.4);
  }
  70% {
    -webkit-box-shadow: 0 0 0 7px rgba(20, 158, 55, 0);
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(20, 158, 55, 0);
  }
}
@keyframes pulse-secondary {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(103, 204, 44, 0.4);
    box-shadow: 0 0 0 0 rgba(103, 204, 44, 0.4);
  }
  70% {
    -moz-box-shadow: 0 0 0 7px rgba(20, 158, 55, 0);
    box-shadow: 0 0 0 7px rgba(20, 158, 55, 0);
  }
  100% {
    -moz-box-shadow: 0 0 0 0 rgba(20, 158, 55, 0);
    box-shadow: 0 0 0 0 rgba(20, 158, 55, 0);
  }
}
</style>
