<template>
  <v-container class="container-dense">
    <h2 class="text-h2 mb-4">Einstellungen</h2>
    <v-row>
      <v-col>
        <v-switch
          v-model="user.notifications"
          inset
          color="primary"
          hide-details="auto"
          label="Push-Benachrichtigungen"
          @update:model-value="updateNotifications(user.notifications)"
        />
        <v-switch
          v-model="user.mails"
          inset
          color="primary"
          hide-details="auto"
          label="E-Mails"
          @update:model-value="updateMail(user.mails)"
        />
        <v-switch
          v-model="user.newsletter"
          inset
          color="primary"
          hide-details="auto"
          label="Newsletter"
          @update:model-value="updateNewsLetter(user.newsletter)"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  props: {
    userId: { type: String, required: true },
  },
  data: () => ({
    user: {},
  }),

  async mounted() {
    await this.loadData()
  },
  methods: {
    async loadData() {
      this.user = await this.$cms.request(
        this.$readUser(this.userId, {
          fields: ['id', 'notifications', 'mails', 'newsletter'],
        })
      )
    },
    async updateNotifications(value) {
      await this.$cms.request(this.$updateUser(this.userId, { notifications: value }))
    },
    async updateMail(value) {
      await this.$cms.request(this.$updateUser(this.userId, { mails: value }))
    },
    async updateNewsLetter(value) {
      await this.$cms.request(this.$updateUser(this.userId, { newsletter: value }))
    },
  },
}
</script>
